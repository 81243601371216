<template>
  <v-row dense>
    <v-col dense>
      <v-btn dense large dark color="program" text @click="funatras">
        <v-icon dark small color="program">mdi-arrow-left</v-icon>
        <pre>&nbsp;</pre>
        Atras
      </v-btn>
      <v-card
        class="mx-auto my-12"
        max-width="800"
        outlined
        shaped
        style="border-width: 30px; background-color: #005445"
      >
        <v-card>
          <v-card-title>
            <v-toolbar flat>
              <v-icon color="program">mdi-account-plus</v-icon>
              <v-divider class="mx-4" vertical></v-divider>
              <v-toolbar-title>Crea tu cuenta</v-toolbar-title>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="1"> </v-col>
              <v-col dense cols="10">
                <v-row dense>
                  <v-col dense cols="4"
                    ><v-form ref="form1" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.nombre"
                        label="Nombre"
                        outlined
                        small
                        :rules="[rules.required]"
                        dense
                      ></v-text-field></v-form
                  ></v-col>
                  <v-col dense cols="4"
                    ><v-form ref="form2" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.apellido_paterno"
                        label="Apellido Paterno"
                        outlined
                        small
                        :rules="[rules.required]"
                        dense
                      ></v-text-field></v-form
                  ></v-col>
                  <v-col dense cols="4"
                    ><v-form ref="form3" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.apellido_materno"
                        label="Apellido Materno"
                        outlined
                        small
                        :rules="[rules.required]"
                        dense
                      ></v-text-field></v-form
                  ></v-col>
                  <v-col dense cols="4"
                    ><v-form ref="form4" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.correo"
                        label="Correo"
                        outlined
                        small
                        :rules="[rules.required, rules.email]"
                        dense
                      ></v-text-field></v-form
                  ></v-col>
                  <v-col dense cols="4"
                    ><v-form ref="form5" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.celular"
                        label="Celular"
                        outlined
                        small
                        :rules="[rules.required, rules.cel]"
                        dense
                      ></v-text-field></v-form
                  ></v-col>
                  <v-col dense cols="4"
                    ><v-form ref="form6" onSubmit="return false;">
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="computedDateFormatted"
                            label="Fecha de Nacimiento"
                            v-bind="attrs"
                            hide-details
                            outlined
                            dense
                            v-on="on"
                            locale="es"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="es"
                          v-model="datos.fecha_nacimiento"
                          @change="cerrarcalendario"
                        ></v-date-picker>
                      </v-menu> </v-form
                  ></v-col>
                  <v-col dense cols="6"
                    ><v-form ref="form7" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.user"
                        label="Usuario"
                        outlined
                        small
                        :rules="specialrule"
                        dense
                        :color="colo"
                        @keydown.enter="handleFormSubmit"
                      >
                        <template v-slot:append>
                          <v-icon
                            :color="color"
                            title="Verificar usuario"
                            @click="handleFormSubmit"
                          >
                            {{ icon }}
                          </v-icon>
                        </template>
                      </v-text-field></v-form
                    ></v-col
                  >
                  <v-col dense cols="6"
                    ><v-form ref="form8" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.pass"
                        :type="type"
                        label="Contraseña"
                        outlined
                        small
                        :rules="[rules.required]"
                        dense
                      >
                        <template v-slot:append>
                          <v-icon title="Ver/Ocultar" @click="hide()">
                            {{ ico }}
                          </v-icon>
                        </template></v-text-field
                      >
                    </v-form>
                  </v-col>
                  <v-col cols="12">
                    <v-divider
                      style="border-width: 3px; background-color: #005445"
                    ></v-divider>
                  </v-col>
                  <br>
                  <br>
                  <v-col dense cols="8"
                    ><v-form ref="form10" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.direccion"
                        label="Dirección"
                        outlined
                        small
                        :rules="[rules.required]"
                        dense
                      ></v-text-field></v-form
                  ></v-col>
                  <v-col dense cols="4"
                    ><v-form ref="form11" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.codigo_postal"
                        label="Código Postal"
                        outlined
                        small
                        :rules="[rules.required, rules.cp]"
                        dense
                      ></v-text-field></v-form
                  ></v-col>
                  <v-col dense cols="6"
                    ><v-form ref="form12" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.razon_social"
                        label="Razón Social"
                        outlined
                        small
                        :rules="[rules.required]"
                        dense
                      ></v-text-field></v-form
                  ></v-col>
                  <v-col dense cols="6"
                    ><v-form ref="form13" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.nombre_comercial"
                        label="Nombre Comercial"
                        outlined
                        small
                        :rules="[rules.required]"
                        dense
                      ></v-text-field></v-form
                  ></v-col>
                  <v-col dense cols="6"
                    ><v-form ref="form14" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.rfc"
                        label="Rfc"
                        outlined
                        small
                        :rules="[rules.required]"
                        dense
                      ></v-text-field></v-form
                  ></v-col>
                  <v-col dense cols="6"
                    ><v-form ref="form15" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.telefono"
                        label="Telefono"
                        outlined
                        small
                        :rules="[rules.required, rules.tel]"
                        dense
                      ></v-text-field></v-form
                  ></v-col>
                  <v-col dense cols="8"></v-col>
                  <v-col dense cols="8"
                    ><v-form ref="form9" onSubmit="return false;">
                      <v-tooltip top v-model="show">
                        <template v-slot:activator="{}">
                          <v-checkbox
                            :rules="[rules.required]"
                            v-model="checkbox"
                          >
                            <template v-slot:label>
                              <div>
                                He leído y acepto el
                                <a
                                  target="_blank"
                                  href="https://contago.com.mx/archivos/Aviso_de_Privacidad.pdf"
                                  @click.stop
                                >
                                  aviso de privacidad.
                                </a>
                              </div>
                            </template>
                          </v-checkbox>
                        </template>
                        {{ mess }}
                      </v-tooltip>
                    </v-form></v-col
                  >
                  <v-col dense cols="4">
                    <v-img
                      src="@/assets/logo_contago.png"
                      transition="scale-transition"
                      max-height="50"
                      max-width="300"
                      @click="contago"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1"> </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dense large dark color="program" text @click="crearcuenta">
              Crear Cuenta
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-card>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogconfirmar" max-width="350">
        <v-card>
          <v-card-title> </v-card-title>
          <v-card-text class="text-h6">
            <h5>¿Desea crear un usuario nuevo?</h5>
          </v-card-text>
          <v-card-actions>
            <v-btn color="program" text @click="dialogconfirmar = false">
              <h4>Cancelar</h4>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="confirmarcuenta">
              <h4>Aceptar</h4>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogcreado" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Usuario creado con éxito</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialogcreado = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogno" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Nombre de usuario no disponible</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialogno = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
  
  <script>
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
export default {
  data: () => ({
    Message: "Internal Error",
    dialogIni: false,
    dialogcreado: false,
    dialogno: false,
    dialogconfirmar: false,
    dialog500: false,
    dialog404: false,
    checkbox: false,
    type: "password",
    ico: "mdi-eye-off",
    icon: "mdi-earth-arrow-right",
    color: "blue darken-4",
    colo: "program",
    mess: "",
    enabled: false,
    show: false,
    por: false,
    datos: {
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      correo: "",
      celular: "",
      fecha_nacimiento: format(
        parseISO(new Date().toISOString()),
        "yyyy-MM-dd"
      ),
      user: "",
      pass: "",
      direccion: "",
      codigo_postal: "",
      razon_social: "",
      nombre_comercial: "",
      rfc: "",
      telefono: "",
    },

    menu: false,

    rules: {
      required: (value) => !!value || "Requerido",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Correo inválido";
      },
      cel: (value) => {
        const pattern = /^([0-9]{10}[0-9]*)$/;
        return pattern.test(value) || "Número inválido";
      },
      tel: (value) => {
        const pattern = /^([0-9][0-9]*)$/;
        return pattern.test(value) || "Número inválido";
      },
      cp: (value) => {
        const pattern = /^([0-9]{5}[0-9]*)$/;
        return pattern.test(value) || "Número inválido";
      },
    },
  }),

  computed: {
    usuario() {
      return this.datos.user;
    },
    specialrule() {
      const rules = [];
      const ruler = (v) => !!v || "Requerido";
      rules.push(ruler);
      const pattern = /^[A-Za-z0-9]{1,40}$/;
      const rule2 = (value) => pattern.test(value) || "Caracteres invalidos";
      rules.push(rule2);
      const rule0 = (value) =>
        (value || "").length >= 6 || "Mínimo 6 caracteres";
      rules.push(rule0);
      const rule1 = (value) =>
        this.icon != "mdi-earth-arrow-right" || "Falta validar usuario";
      rules.push(rule1);
      const rule = (value) => this.icon != "mdi-close" || "Usuario inválido";
      rules.push(rule);
      return rules;
    },

    computedDateFormatted() {
      var localLocale = moment(this.datos.fecha_nacimiento).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
      return this.datos.fecha_nacimiento ? localLocale : "";
    },
  },
  watch: {
    usuario() {
      this.icon = "mdi-earth-arrow-right";
      this.color = "blue darken-4";
      this.colo = "program";
      this.mess = "";
    },

    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  methods: {
    crearcuenta() {
      var a = this.$refs.form1.validate();
      var b = this.$refs.form2.validate();
      var c = this.$refs.form3.validate();
      var d = this.$refs.form4.validate();
      var e = this.$refs.form5.validate();
      var f = this.$refs.form6.validate();
      var g = this.$refs.form7.validate();
      var h = this.$refs.form8.validate();
      var i = this.$refs.form9.validate();
      var j = this.$refs.form10.validate();
      var k = this.$refs.form11.validate();
      var l = this.$refs.form12.validate();
      var m = this.$refs.form13.validate();
      var n = this.$refs.form14.validate();
      var o = this.$refs.form15.validate();

      if (a && b && c && d && e && f && g && h && i && j && k && l && m && n && o) {
        this.dialogconfirmar = true;
      }
    },

    confirmarcuenta() {
      var vue = this;
      vue.dialogconfirmar = false;
      vue.dialogIni = true;
      var Params = {
        nombre: vue.datos.nombre,
        apellido_paterno: vue.datos.apellido_paterno,
        apellido_materno: vue.datos.apellido_materno,
        correo: vue.datos.correo,
        celular: vue.datos.celular,
        fecha_nacimiento: vue.datos.fecha_nacimiento,
        user: vue.datos.user,
        pass: vue.datos.pass,
        direccion: vue.datos.direccion,
        codigo_postal: vue.datos.codigo_postal,
        razon_social: vue.datos.razon_social,
        nombre_comercial: vue.datos.nombre_comercial,
        rfc: vue.datos.rfc,
        telefono: vue.datos.telefono,
      };
      axios
        .post("api/Monedero/InsertaClientes", Params)
        .then(function (response) {
          vue.dialogIni = false;
          vue.dialogcreado = true;
          vue.clear();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          vue.clear();
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    funatras() {
      // this.$router.push({ name: "Home" });
      location.href = "Login";
      document.getElementById("todo").style.display = "none";
    },

    clear() {
      this.$refs.form1.reset();
      this.$refs.form2.reset();
      this.$refs.form3.reset();
      this.$refs.form4.reset();
      this.$refs.form5.reset();
      this.$refs.form6.reset();
      this.$refs.form7.reset();
      this.$refs.form8.reset();
      this.$refs.form9.reset();
      this.$refs.form10.reset();
      this.$refs.form11.reset();
      this.$refs.form12.reset();
      this.$refs.form13.reset();
      this.$refs.form14.reset();
      this.$refs.form15.reset();
    },

    handleFormSubmit() {
      var vue = this;
      if (vue.datos.user == "") {
        vue.$refs.form7.validate();
        return;
      }
      if (vue.datos.user.length < 6) {
        vue.$refs.form7.validate();
        return;
      }
      if (vue.icon == "mdi-check" || vue.icon == "mdi-close") {
        return;
      }
      var Params = {
        user: vue.datos.user,
      };
      axios
        .post("api/Monedero/VerificarUsuario", Params)
        .then(function (response) {
          if (response.data.user == "no") {
            vue.boolno();
          } else {
            vue.boolyes();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    hide() {
      if (this.type == "password") {
        this.type = "";
        this.ico = "mdi-eye";
      } else {
        this.type = "password";
        this.ico = "mdi-eye-off";
      }
    },

    boolyes() {
      this.show = true;
      this.icon = "mdi-check";
      this.color = "success";
      this.colo = "success";
      this.mess = "Usuario Válido";
      this.$refs.form7.validate();
      setTimeout(() => {
        this.show = false;
        this.colo = "program";
      }, 2000);
    },
    boolno() {
      this.show = true;
      this.icon = "mdi-close";
      this.color = "error";
      this.colo = "error";
      this.mess = "Usuario Inválido";
      this.$refs.form7.validate();
      setTimeout(() => {
        this.show = false;
        this.colo = "program";
      }, 2000);
    },
    contago() {
      window.open("https://contago.com.mx");
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    cerrarcalendario() {
      this.menu = false;
    },
  },
  created() {
    if (true) {
      var localLocale = moment(this.datos.fecha_nacimiento).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
    }
  },
};
</script>