<template>
  <v-card
    class="mx-auto"
    max-width="50%"
    outlined
    shaped
    style="border-width: 30px; background-color: #005445"
  >
    <v-card-title>
      <v-toolbar flat>
        <v-icon color="program">mdi-sale</v-icon>
        <v-divider class="mx-4" vertical></v-divider>
        <v-toolbar-title>Promociones</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
    </v-card-title>
    <br>
    <!-- <v-card >
      <br />
      <v-row>
        <v-spacer></v-spacer>
        <v-col allign="center" justify="center">
          <v-card-text>
            <div class="text-h5">{{ item.titulo }}</div>
          </v-card-text>
          <v-card-text>
            <div class="text-h5">{{ item.contenido }}</div>
          </v-card-text>
          <v-card-text>
            <div class="text-h5">{{ item.imagen }}</div>
          </v-card-text>
          <v-card-text>
            <div class="text-h5">{{ item.ruta }}</div>
          </v-card-text>
          <v-card-text>
            <div class="text-h5">{{ item.fecha }}</div>
          </v-card-text>
          <v-card-text>
            <div class="text-h5">{{ item.sucursal }}</div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card> -->

    <v-card>
      <template v-for="(item, i) in items">
        <v-row v-bind:key="i">
          <v-spacer />
          <v-col cols="10">
              <template>
                <template v-if="item.titulo != null">
                  <h2 class="justify-center">{{ item.titulo }}</h2>
                </template>
                <template v-if="item.contenido != null">
                  <h3 class="text-justify">{{ item.contenido }}</h3>
                </template>
                <template v-if="item.imagen != null">
                  <div style="height: 100%">
                    <!-- <div style="height: 300px"></div> -->
                    <img
                      class="justify-center"
                      id="imglogo"
                      style="height: 100%; width: 100%; object-fit: contain"
                      v-bind:src="'data:image/*;base64,' + item.imagen.imagen"
                    />
                  </div>
                </template>
              </template>
              <template v-if="item.sucursal != null">
                <h3 class="justify-center">{{ item.sucursal }}</h3>
              </template>
          </v-col>
          <v-spacer />
        </v-row>
      </template>
    </v-card>
    
    <!-- <template v-slot:[`item.titulo`]="{ item }">
        <template v-if="item.titulo != ''">
          {{ item.titulo }}
        </template></template
      > -->

    <!-- <template v-for="item in promociones" >
        <v-row>
          <v-col cols="10">
            <template v-if="item.titulo != null">
              <h2 class="justify-center">{{ item.titulo }}</h2>
            </template>
          </v-col>
        </v-row>
      </template> -->

    <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog500" max-width="350">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-h6">
          <h5>Problemas de conexión</h5>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="program" text @click="dialog500 = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog404" max-width="350">
      <v-card>
        <v-card-title class="headline"> Error </v-card-title>
        <v-card-text class="text-h6">
          <h5>{{ Message }}</h5>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="program" text @click="dialog404 = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
import { VueEditor } from "vue2-editor";

export default {
  data: () => ({
    Message: "Internal Error",
    dialogIni: false,
    dialog404: false,
    dialog500: false,
    dialogsi: false,
    titulo: "",
    contenido: "",
    imagen: "",
    ruta: "",
    sucursal: "",
    fecha: "",
    item: null,
    items: [],
  }),
  watch: {},
  computed: {},
  methods: {
    initialize() {
      this.items = [];
      this.cargarpromociones();
    },

    cargarpromociones() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        titulo: vue.titulo,
        contenido: vue.contenido,
        imagen: vue.imagen,
        ruta: vue.ruta,
        sucursal: vue.sucursal,
        fecha: vue.fecha,
      };
      axios
        .post("api/Monedero/ConsultaPromociones", Params)
        .then(function (response) {
          vue.dialogIni = false;
          vue.items = response.data;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data.Message;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
  },
  mounted() {
    this.$store.dispatch("autologin", {
      franquiciante: true,
      franquiciatario: true,
      empleado: true,
      cliente: true,
    });
    this.initialize();
  },
  created() {},
};
</script>