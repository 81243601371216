<template>
  <v-card
    class="mx-auto"
    max-width="60%"
    outlined
    shaped
    style="border-width: 30px; background-color: #005445"
  >
    <v-card-title>
      <v-toolbar flat>
        <v-icon color="program">mdi-currency-usd</v-icon>
        <v-divider class="mx-4" vertical></v-divider>
        <v-toolbar-title>Mi Saldo</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
    </v-card-title>
    <v-card>
      <br />
      <v-row>
        <v-spacer></v-spacer>
        <v-col allign="center" justify="center">
            <div class="text-h5 mb-1 text--primary">Saldo</div>
            <div class="text-h4">${{ saldo }}<br /></div>
            <br>
            <v-btn rounded outlined color="program" @click="dialogsaldo = true"
              ><v-icon>mdi-plus</v-icon>Agregar saldo</v-btn
            >
          <v-dialog v-model="dialogsaldo" max-width="30%">
            <v-card>
              <v-card-title class="headline justify-center">
                ¿Cómo agregar saldo?
              </v-card-title>
              <v-card-text>
                <h3>Ve a configuración para agregar saldo al monedero.</h3>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" width="50%" @click="dialogsaldo = false"
                  >Cerrar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <br />
    </v-card>
    <v-divider
      style="border-width: 10px; background-color: #005445"
    ></v-divider>
    <v-card-title>
      <v-toolbar flat>
        <v-icon color="program">mdi-swap-horizontal</v-icon>
        <v-divider class="mx-4" vertical></v-divider>
        <v-toolbar-title>Pagar</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
    </v-card-title>
    <v-card>
      <v-card-actions>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <v-row>
          <v-spacer></v-spacer>
          <v-btn
            dense
            title="Pagar"
            x-large
            max-height
            dark
            color="program"
            @click="dialogpagar = true"
          >
            <h3>Pagar con Saldo</h3>
          </v-btn>
          <v-dialog persistent v-model="dialogpagar" max-width="50%">
            <v-card>
              <v-card-title class="headline justify-center">
                Pagar con Saldo
              </v-card-title>
              <br />
              <v-card-text>
                <v-form ref="form1" onSubmit="return false;">
                  <v-text-field
                    v-model="codigodigitos"
                    label="Ingresar el  código"
                    outlined
                    :rules="[rules.required, rules.codigo]"
                    dense
                    @keydown.enter="blurAll"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" width="30%" @click="ingresaryvalidar"
                  ><v-icon>mdi-check-circle-outline</v-icon>Pagar</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn color="primary" width="30%" @click="dialogpagar = false"
                  >Cerrar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
        </v-row>
        <br />
      </v-card-actions>

      <v-dialog v-model="dialogvalidar" max-width="30%">
        <v-card>
          <br />
          <v-card-title class="text-center">
            <v-spacer></v-spacer>
            <h2>La venta esta pagada:</h2>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-title class="text-center">
            <v-spacer></v-spacer>
            <h2>
              {{ pagado }}
            </h2>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" width="50%" @click="dialogvalidar = false"
              >Ok</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="program" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    dialogIni: false,
    dialogsaldo: false,
    dialogpuntos: false,
    dialogpagar: false,
    dialogvalidar: false,
    dialogganarpuntos: false,
    codigodigitos: "",
    pagado: "",
    dialog404: false,
    dialog500: false,
    Message: "Internal Error",
    items: [],
    saldo: "0.00",
    puntos: "0",
    rules: {
      required: (value) => !!value || "Requerido",
      codigo: (value) => {
        const pattern = /^[0-9]{5}$/;
        return pattern.test(value) || "Requiere 5 digitos";
      },
    },
  }),
  computed: {},
  watch: {},
  methods: {
    versaldo() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        user: vue.$store.state.user,
      };
      axios
        .post("api/Monedero/ConsultaMonedero", Params)
        .then(function (response) {
          vue.dialogIni = false;
          vue.saldo = response.data.saldo;
          vue.puntos = response.data.puntos;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data.Message;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    ingresarcodigo() {
      var vue = this;
      var a = this.$refs.form1.validate();
      if (a) {
        var Params = {
          codigodigitos: vue.codigodigitos,
          user: vue.$store.state.user,
          saldo: vue.saldo,
          puntos: vue.puntos,
        };
        axios
          .post("api/Monedero/IngresarCodigo", Params)
          .then(function (response) {
            vue.clear();
            vue.dialogpagar = false;
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data.Message;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    ganarpuntos() {
      var vue = this;
      var Params = {
        user: vue.$store.state.user,
        saldo: vue.saldo,
        puntos: vue.puntos,
      };
      axios
        .post("api/Monedero/GanarPuntos", Params)
        .then(function (response) {
          vue.clear();
          vue.dialogpagar = false;
          vue.reloadPage();
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data.Message;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    validarventa() {
      var vue = this;
      vue.dialogvalidar = true;
      var Params = {
        codigodigitos: vue.codigodigitos,
        pagado: vue.pagado,
      };
      axios
        .post("api/Monedero/ValidarVenta", Params)
        .then(function (response) {
          vue.pagado = response.data.pagado;
        })
        .catch(function (error) {
          vue.clear();
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data.Message;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    async ingresaryvalidar() {
      this.ingresarcodigo();
      await new Promise(resolve => setTimeout(resolve, 3000));
      this.validarventa();
    },

    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },

    clear() {
      this.$refs.form1.reset();
    },

    blurall() {
      var tmp = document.createElement("input");
      document.body.appendChild(tmp);
      tmp.focus();
      document.body.removeChild(tmp);
    },
  },

  mounted() {
    this.$store.dispatch("autologin", {
      franquiciante: false,
      franquiciatario: false,
      empleado: false,
      cliente: true,
    });
    this.versaldo();
  },
  created() {},
};
</script>