import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import Login from '../views/Login.vue'
import Menu from '../views/Menu.vue'
import Crear_Cuenta from '../components/Crear_Cuenta.vue'
import Saldo from '../components/Saldo.vue'
import Promociones from '../components/Promociones.vue'
import Historial from '../components/Historial.vue'
import Configuracion from '../components/Configuracion.vue'
import Crear_Usuarios from '../components/Crear_Usuarios.vue'
import Venta from '../components/Venta.vue'
import Datos from '../components/Datos.vue'
// import { component } from 'vue/types/umd'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/Login',
        name: 'Login',
        component: Login
    },
    {
        path: '/Menu',
        name: 'Menu',
        component: Menu
    },
    {
        path: '/Crear_cuenta',
        name: 'Crear_Cuenta',
        component: Crear_Cuenta
    },
    {
        path: '/Saldo',
        name: 'Saldo',
        component: Saldo
    },
    {
        path: '/Promociones',
        name: 'Promociones',
        component: Promociones
    },
    {
        path: '/Historial',
        name: 'Historial',
        component: Historial
    },
    {
        path: '/Crear_Usuarios',
        name: 'Crear_Usuarios',
        component: Crear_Usuarios
    },
    {
        path: '/Venta',
        name: 'Venta',
        component: Venta
    },
    {
        path: '/Datos',
        name: 'Datos',
        component: Datos
    },
    {
        path: '/Configuracion',
        name: 'Configuracion',
        component: Configuracion
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


export default router