// import Vue from 'vue';
// import Vuetify from 'vuetify/lib/framework';

// Vue.use(Vuetify);

// export default new Vuetify({
// });

import Vue from "vue";
import Vuetify from "vuetify";
import VuetifyAsyncValidation from "vuetify-async-validation";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);
Vue.use(VuetifyAsyncValidation);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                // program: '#dba343',
                program: '#005445',
                primary: '#045444',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                // program: '#45B297',
            },
        },
    },
});