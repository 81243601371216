<template>
  <v-card
    class="mx-auto"
    max-width="60%"
    outlined
    shaped
    style="border-width: 30px; background-color: #005445"
  >
    <v-card-title>
      <v-toolbar flat>
        <v-icon color="program">mdi-wallet-giftcard</v-icon>
        <v-divider class="mx-4" vertical></v-divider>
        <v-toolbar-title>Recargar Monedero</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
    </v-card-title>
    <v-card>
      <br />
      <v-row>
        <v-col allign="center" justify="center">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="3"></v-col>
                <v-col cols="6">
                  <v-form ref="form1" onSubmit="return false;">
                    <v-text-field
                      prefix="$"
                      v-model="saldo"
                      label="Insertar saldo"
                      outlined
                      :rules="specialrule"
                      dense
                      @keydown.enter="blurAll"
                    ></v-text-field>
                  </v-form>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn
                      dense
                      title="Recargar Saldo"
                      small
                      rounded
                      large
                      dark
                      color="program"
                      @click="recargarsaldo"
                    >
                      Recargar Saldo
                      <pre>&nbsp;</pre>
                      <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <v-card-title>
      <v-toolbar flat>
        <v-icon color="program">mdi-card-account-details-outline</v-icon>
        <v-divider class="mx-4" vertical></v-divider>
        <v-toolbar-title>Datos Personal</v-toolbar-title>
        <v-spacer />
        <v-toolbar-title>{{ $store.state.user }}</v-toolbar-title>
        <v-divider class="mx-4" vertical></v-divider>
        <v-btn
          dense
          title="Editar"
          small
          large
          dark
          color="program"
          @click="funeditar"
        >
          Editar
          <pre>&nbsp;</pre>
          <v-icon>mdi-account-edit-outline</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card>
      <br />
      <v-row>
        <v-col cols="4">
          <v-card-text>
            <div class="text-h6 mb-1 text--primary">Nombre:</div>
            <div
              class="text-h6"
              style="
                border: 2px solid #005445;
                border-radius: 15px;
                text-align: center;
              "
            >
              {{ nombre }}<br />
            </div>
          </v-card-text>
        </v-col>
        <v-col cols="4">
          <v-card-text>
            <div class="text-h6 mb-1 text--primary">Apellido Paterno:</div>
            <div
              class="text-h6"
              style="
                border: 2px solid #005445;
                border-radius: 15px;
                text-align: center;
              "
            >
              {{ apellido_paterno }}<br />
            </div>
          </v-card-text>
        </v-col>
        <v-col cols="4">
          <v-card-text>
            <div class="text-h6 mb-1 text--primary">Apellido Materno:</div>
            <div
              class="text-h6"
              style="
                border: 2px solid #005445;
                border-radius: 15px;
                text-align: center;
              "
            >
              {{ apellido_materno }}<br />
            </div>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-card-text>
            <div class="text-h6 mb-1 text--primary">Correo:</div>
            <div
              class="text-h6"
              style="
                border: 2px solid #005445;
                border-radius: 15px;
                text-align: center;
              "
            >
              {{ correo }}<br />
            </div>
          </v-card-text>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="4">
          <v-card-text>
            <div class="text-h6 mb-1 text--primary">Celular:</div>
            <div
              class="text-h6"
              style="
                border: 2px solid #005445;
                border-radius: 15px;
                text-align: center;
              "
            >
              {{ celular }}<br />
            </div>
          </v-card-text>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="4">
          <v-card-text>
            <div class="text-h6 mb-1 text--primary">Fecha de Nacimiento:</div>
            <div
              class="text-h6"
              style="
                border: 2px solid #005445;
                border-radius: 15px;
                text-align: center;
              "
            >
              {{ fecha_nacimiento }}<br />
            </div>
          </v-card-text>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card>

    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="program" dark>
        <v-card-text>
          Cargando
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogeditar" max-width="70%">
      <v-card
        max-width="100%"
        outlined
        style="border-width: 30px; background-color: #005445"
      >
        <v-card>
          <v-card-title>
            <v-toolbar flat>
              <v-icon color="program">mdi-pencil</v-icon>
              <v-divider class="mx-4" vertical></v-divider>
              <v-toolbar-title>Editar Datos</v-toolbar-title>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="1"> </v-col>
              <v-col dense cols="10">
                <v-row dense>
                  <v-col dense cols="4"
                    ><v-form ref="form2" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.nombre"
                        label="Nombre"
                        outlined
                        small
                        :rules="[rules.required]"
                        dense
                      ></v-text-field></v-form
                  ></v-col>
                  <v-col dense cols="4"
                    ><v-form ref="form3" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.apellido_paterno"
                        label="Apellido Paterno"
                        outlined
                        small
                        :rules="[rules.required]"
                        dense
                      ></v-text-field></v-form
                  ></v-col>
                  <v-col dense cols="4"
                    ><v-form ref="form4" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.apellido_materno"
                        label="Apellido Materno"
                        outlined
                        small
                        :rules="[rules.required]"
                        dense
                      ></v-text-field></v-form
                  ></v-col>
                  <v-col dense cols="4"
                    ><v-form ref="form5" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.correo"
                        label="Correo"
                        outlined
                        small
                        :rules="[rules.required, rules.email]"
                        dense
                      ></v-text-field></v-form
                  ></v-col>
                  <v-col dense cols="4"
                    ><v-form ref="form6" onSubmit="return false;">
                      <v-text-field
                        v-model="datos.celular"
                        label="Celular"
                        outlined
                        small
                        :rules="[rules.required, rules.cel]"
                        dense
                      ></v-text-field></v-form
                  ></v-col>
                  <v-col dense cols="4"
                    ><v-form ref="form7" onSubmit="return false;">
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="computedDateFormatted"
                            label="Fecha de Nacimiento"
                            v-bind="attrs"
                            hide-details
                            outlined
                            dense
                            v-on="on"
                            locale="es"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="es"
                          v-model="datos.fecha_nacimiento"
                          @change="cerrarcalendario"
                        ></v-date-picker>
                      </v-menu> </v-form
                  ></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              dense
              title="Realizar cambios"
              large
              dark
              color="program"
              @click="editardatos"
            >
              Realizar cambios
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          <br />
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogconfirmar" max-width="350">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text class="text-h6">
          <h5>¿Desea editar sus datos personales?</h5>
        </v-card-text>
        <v-card-actions>
          <v-btn color="program" text @click="dialogconfirmar = false">
            <h4>Cancelar</h4>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="program" text @click="confirmardatos">
            <h4>Aceptar</h4>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogeditado" max-width="350">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-h6">
          <h5>Datos actualizados con éxito</h5>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="program" text @click="reloadPage"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialog500" max-width="350">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-h6">
          <h5>Problemas de conexión</h5>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="program" text @click="dialog500 = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialog404" max-width="350">
      <v-card>
        <v-card-title class="headline"> Error </v-card-title>
        <v-card-text class="text-h6">
          <h5>{{ Message }}</h5>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="program" text @click="dialog404 = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
export default {
  data: () => ({
    Message: "Internal Error",
    saldo: "0.00",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    correo: "",
    celular: "",
    fecha_nacimiento: "",
    dialog: false,
    dialogeditar: false,
    dialogconfirmar: false,
    dialogeditado: false,
    dialog404: false,
    dialog500: false,
    datos: {
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      correo: "",
      celular: "",
      fecha_nacimiento: format(
        parseISO(new Date().toISOString()),
        "yyyy-MM-dd"
      ),
    },
    menu: false,
    rules: {
      required: (value) => !!value || "Requerido",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Correo inválido";
      },
      cel: (value) => {
        const pattern = /^([0-9]{10}[0-9]*)$/;
        return pattern.test(value) || "Número inválido";
      },
    },
  }),

  computed: {
    specialrule() {
      const rules = [];
      const ruler = (v) => !!v || "Requerido";
      rules.push(ruler);
      const pattern =
        /^([1-9][0-9]*\.[0-9][0-9]|[0]\.[0-9][1-9]|[0]\.[1-9][0-9])$/;
      const rule = (value) => pattern.test(value) || "Invalido, ejemplo: 0.01";
      rules.push(rule);
      return rules;
    },

    computedDateFormatted() {
      var localLocale = moment(this.datos.fecha_nacimiento).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
      return this.datos.fecha_nacimiento ? localLocale : "";
    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  methods: {
    recargarsaldo() {
      var vue = this;
      var a = this.$refs.form1.validate();
      if (a) {
        vue.dialog = true;
        var Params = {
          user: vue.$store.state.user,
          saldo: vue.saldo,
        };
        axios
          .post("api/Monedero/RecargarSaldo", Params)
          .then(function (response) {
            vue.dialog = false;
            vue.clearsaldo();
          })
          .catch(function (error) {
            vue.clearsaldo();
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data.Message;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    verdatos() {
      var vue = this;
      vue.dialog = true;
      var Params = {
        user: vue.$store.state.user,
      };
      axios
        .post("api/Monedero/ConsultaDatos", Params)
        .then(function (response) {
          vue.dialog = false;
          vue.user = response.data.user;
          vue.nombre = response.data.nombre;
          vue.apellido_paterno = response.data.apellido_paterno;
          vue.apellido_materno = response.data.apellido_materno;
          vue.correo = response.data.correo;
          vue.celular = response.data.celular;
          vue.fecha_nacimiento = response.data.fecha_nacimiento;
        })
        .catch(function (error) {
          vue.dialog = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data.Message;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    editardatos() {
      var b = this.$refs.form2.validate();
      var c = this.$refs.form3.validate();
      var d = this.$refs.form4.validate();
      var e = this.$refs.form5.validate();
      var f = this.$refs.form6.validate();
      var g = this.$refs.form7.validate();

      if (b && c && d && e && f && g) {
        this.dialogconfirmar = true;
      }
    },

    confirmardatos() {
      var vue = this;
      vue.dialofconfirmar = false;
      vue.dialog = true;
      var Params = {
        user: vue.$store.state.user,
        nombre: vue.datos.nombre,
        apellido_paterno: vue.datos.apellido_paterno,
        apellido_materno: vue.datos.apellido_materno,
        correo: vue.datos.correo,
        celular: vue.datos.celular,
        fecha_nacimiento: vue.datos.fecha_nacimiento,
      };
      axios
        .post("api/Monedero/EditarClientes", Params)
        .then(function (response) {
          vue.dialog = false;
          (vue.dialogeditado = true), vue.cleardatos();
        })
        .catch(function (error) {
          vue.dialog = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data.Message;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    cerrarcalendario() {
      this.menu = false;
    },

    clearsaldo() {
      this.$refs.form1.reset();
    },

    cleardatos() {
      this.$refs.form2.reset();
      this.$refs.form3.reset();
      this.$refs.form4.reset();
      this.$refs.form5.reset();
      this.$refs.form6.reset();
      this.$refs.form7.reset();
    },

    blurall() {
      var tmp = document.createElement("input");
      document.body.appendChild(tmp);
      tmp.focus();
      document.body.removeChild(tmp);
    },

    funeditar() {
      this.dialogeditar = true;
    },
  },
  mounted() {
    this.$store.dispatch("autologin", {
      franquiciante: false,
      franquiciatario: false,
      empleado: false,
      cliente: true,
    });
    this.verdatos();
  },
  created() {
    if (true) {
      var localLocale = moment(this.datos.fecha_nacimiento).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
    }
  },
};
</script>