<template>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
  }),
  methods: {
  },
  created()
  {
    location.href = 'login';
  }
};
</script>