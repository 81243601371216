<template>
  <p id="demo">hola</p>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({}),
  methods: {
  },
  created() {
  },
};
</script>