<template>
  <v-card
    class="mx-auto"
    max-width="90%"
    outlined
    shaped
    style="border-width: 30px; background-color: #005445"
  >
    <div>
      <v-card-title>
        <v-toolbar flat>
          <v-icon color="program">mdi-history</v-icon>
          <v-divider class="mx-4" vertical></v-divider>
          <v-toolbar-title>Historial</v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-row
        ><v-col>
          <v-data-table
            dense
            :headers="headers"
            :items="items"
            class="elevation-1"
            :footer-props="{ itemsPerPageText: 'Filas por página:' }"
            :items-per-page="-1"
          >
            <template v-slot:top>
              <v-card-title>
                <v-toolbar flat>
                  <v-menu
                    v-model="menuDateBuscaA"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computedDateFormattedMomentjsA"
                        label="Fecha de Búsqueda Inicial: "
                        readonly
                        v-bind="attrs"
                        hide-details
                        v-on="on"
                        locale="es"
                        class="tam"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="es"
                      v-model="fecha1"
                      @change="funfecha1"
                    ></v-date-picker>
                  </v-menu>
                  <v-divider class="mx-4" vertical></v-divider>
                  <v-checkbox v-model="check"></v-checkbox>
                  <v-menu
                    v-model="menuDateBuscaB"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computedDateFormattedMomentjsB"
                        label="Fecha de Búsqueda Final: "
                        readonly
                        v-bind="attrs"
                        hide-details
                        v-on="on"
                        locale="es"
                        :disabled="!check"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="es"
                      v-model="fecha2"
                      @change="funfecha2"
                    ></v-date-picker>
                  </v-menu>
                </v-toolbar>
              </v-card-title>
            </template>
            <template v-slot:[`item.id`]="{ item }">
              {{ items.indexOf(item) + 1 }}
            </template>
            <!-- <template v-slot:[`item.saldo`]="{ item }">
              <template v-if="item.saldo != ''">
                ${{ item.saldo }}
              </template></template
            > -->
            <template v-slot:[`item.monto`]="{ item }">
              <template v-if="item.monto != ''">
                ${{ item.monto }}
              </template></template
            >
            <template v-slot:[`item.totalcuenta`]="{ item }">
              <template v-if="item.totalcuenta != ''">
                ${{ item.totalcuenta }}
              </template></template
            >
            <template v-slot:no-data>
              <br />
              <p>Sin datos disponibles</p>
            </template>
          </v-data-table>
          <template>
            <br />
            <v-row>
              <v-col cols="12">
                <v-toolbar dense small flat>
                  <v-spacer></v-spacer>
                  <v-icon color="program">mdi-cash</v-icon>
                  <pre>&nbsp;&nbsp;</pre>
                  <h4 class="mx-10">Consumo</h4>
                  <v-spacer></v-spacer>
                  <!-- <h4>$ {{ total_saldo }}</h4> -->
                  <h4>$ {{ total_monto }}</h4>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </v-col>
            </v-row>
          </template>
          <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
            <v-card color="primary" dark>
              <v-card-text>
                Cargando
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="dialog500" max-width="350">
            <v-card>
              <v-card-title></v-card-title>
              <v-card-text class="text-h6">
                <h5>Problemas de conexión</h5>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="program" text @click="dialog500 = false">
                  Aceptar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="dialog404" max-width="350">
            <v-card>
              <v-card-title class="headline"> Error </v-card-title>
              <v-card-text class="text-h6">
                <h5>{{ Message }}</h5>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="program" text @click="dialog404 = false">
                  Aceptar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="dialogA" max-width="400">
            <v-card>
              <v-card-title></v-card-title>
              <v-card-text class="text-h6">
                <h5>
                  Solo se puede buscar del dia de hoy o de fechas anteriores.
                </h5>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="program" text @click="dialogA = false">
                  Aceptar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="dialogB" max-width="400">
            <v-card>
              <v-card-title></v-card-title>
              <v-card-text class="text-h6">
                <h5>
                  Solo se puede buscar cuando la fecha inicial es menor o igual
                  que la fecha final.
                </h5>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="program" text @click="dialogB = false">
                  Aceptar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <div class="under" id="insertar"></div>
      </v-row>
    </div>
  </v-card>
</template>

<style>
.under {
  z-index: -10;
  padding: 0;
  margin: 0;
}
.down {
  top: 20px;
}
</style>

<script>
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  data: () => ({
    total_saldo: "0.00",
    total_monto: "0.00",
    // total_propina: "0.00",
    // total_tot: "0.00",
    // total_puntos: "0",
    Message: "Internal Error",
    menuDateBuscaA: false,
    menuDateBuscaB: false,
    fecha1: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    fecha2: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    check: false,
    dialogIni: false,
    dialog500: false,
    dialog404: false,
    dialogA: false,
    dialogB: false,
    item: null,
    headers: [
      { text: "Id", value: "id", align: "start", sortable: false },
      // { text: "Vendedor", value: "vendedor", align: "center", sortable: false },
      // { text: "Folio", value: "folio", align: "center", sortable: false },
      // { text: "Monto", value: "saldo", align: "center", sortable: false },
      // { text: "Fecha", value: "datos", align: "center" },
      { text: "Monto", value: "monto", align: "center", sortable: false },
      {
        text: "Cuenta Total",
        value: "totalcuenta",
        align: "center",
        sortable: false,
      },
      { text: "Fecha", value: "fecha_pago", align: "center" },
    ],
    items: [],
  }),

  watch: {
    check(val) {
      val || this.closef();
    },
  },

  computed: {
    computedDateFormattedMomentjsA() {
      var localLocale = moment(this.fecha1).format("DD MMM YYYY");
      moment.locale("es");
      return this.fecha1 ? localLocale : "";
    },
    computedDateFormattedMomentjsB() {
      var localLocale = moment(this.fecha2).format("DD MMM YYYY");
      moment.locale("es");
      return this.fecha2 ? localLocale : "";
    },
  },

  methods: {
    initialize() {
      this.items = [];
      // this.cargarhistorial();
      this.cargarhistorialnuevo();
    },

    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },

    cargarhistorial() {
      var vue = this;
      vue.dialogIni = true;
      if (vue.$store.state.negocio == null || vue.$store.state.negocio == "") {
        vue.reloadPage();
        return;
      }
      var Params = {
        negocio: vue.$store.state.negocio,
        fecha_i: vue.fecha1,
        fecha_f: vue.fecha2,
      };
      axios
        .post("api/Monedero/ConsultaHistorial", Params)
        .then(function (response) {
          vue.items = response.data;
          var saldo = 0;
          for (var i = 0; i < response.data.length; i++) {
            saldo = saldo + parseFloat(response.data[i].saldo);
          }

          vue.total_saldo = saldo.toFixed(2);

          vue.dialogIni = false;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data.Message;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    cargarhistorialnuevo() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        id_negocio: vue.$store.state.id_negocio,
        fecha_i: vue.fecha1,
        fecha_f: vue.fecha2,
      };
      axios
        .post("api/Monedero/ConsultaHistorialNuevo", Params)
        .then(function (response) {
          vue.items = response.data;
          var monto = 0;
          for (var i = 0; i < response.data.length; i++) {
            monto = monto + parseFloat(response.data[i].monto);
          }

          vue.total_monto = monto.toFixed(2);

          vue.dialogIni = false;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data.Message;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    funfecha1() {
      this.menuDateBuscaA = false;
      if (!this.check) {
        this.fecha2 = this.fecha1;
      }
      var a = this.fecha1;
      var b = this.fecha2;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      let datf = new Date(
        b.substr(0, 4),
        (parseInt(b.substr(5, 2)) - 1).toString(),
        b.substr(8, 2)
      );
      let hoy = new Date();
      if (hoy < dati) {
        this.dialogA = true;
        this.fecha1 = format(parseISO(new Date().toISOString()), "yyyy-MM-dd");
        this.fecha2 = this.fecha1;
      } else if (dati > datf) {
        this.dialogB = true;
        var newDate = new Date(dati.setDate(dati.getDate()));
        this.fecha2 = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      }
      // this.cargarhistorial();
      this.cargarhistorialnuevo();
    },

    funfecha2() {
      this.menuDateBuscaB = false;
      var a = this.fecha1;
      var b = this.fecha2;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      let datf = new Date(
        b.substr(0, 4),
        (parseInt(b.substr(5, 2)) - 1).toString(),
        b.substr(8, 2)
      );
      let hoy = new Date();
      if (hoy < datf) {
        this.dialogA = true;
        var newDate = new Date(dati.setDate(dati.getDate()));
        this.fecha2 = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      } else if (dati > datf) {
        this.dialogB = true;
        var newDate = new Date(dati.setDate(dati.getDate()));
        this.fecha2 = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      }
      // this.cargarhistorial();
      this.cargarhistorialnuevo();
    },

    closef() {
      var a = this.fecha1;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 10).toString(),
        a.substr(8, 2)
      );
      var newDate = new Date(dati.setDate(dati.getDate()));
      this.fecha2 = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      // this.cargarhistorial();
      this.cargarhistorialnuevo();
    },
  },

  mounted() {
    this.$store.dispatch("autologin", {
      franquiciante: false,
      franquiciatario: true,
      empleado: true,
      cliente: false,
    });
    this.initialize();
  },

  created() {
    if (true) {
      var localLocale = moment(this.fecha1).format("DD MMM YYYY");
      moment.locale("es");
    }
    if (true) {
      var localLocale = moment(this.fecha2).format("DD MMM YYYY");
      moment.locale("es");
    }
  },
};
</script>