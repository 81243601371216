<template>
  <v-card
    class="mx-auto"
    max-width="60%"
    outlined
    shaped
    style="border-width: 30px; background-color: #005445"
  >
    <div>
      <v-card-title>
        <v-toolbar flat>
          <v-icon color="program">mdi-cash-register</v-icon>
          <v-divider class="mx-4" vertical></v-divider>
          <v-toolbar-title>Venta</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
      </v-card-title>
      <v-row>
        <v-col cols="12">
          <v-card>
            <br />
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="2"></v-col>
                  <v-col cols="8">
                    <br />
                    <template>
                      <v-form ref="form1" onSubmit="return false;">
                        <v-text-field
                          v-model="celular"
                          label="Número de Celular"
                          outlined
                          :rules="[rules.required, rules.cel]"
                          dense
                          @keydown.enter="blurAll"
                        ></v-text-field>
                      </v-form>
                    </template>
                  </v-col>
                  <v-col cols="2"></v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-row dense>
              <v-col dense cols="1"></v-col>
              <v-col dense cols="10">
                <v-row dense>
                  <v-col dense cols="0" sm="4"> </v-col>
                  <v-col dense cols="12" sm="4">
                    <v-btn
                      dense
                      title="Enviar"
                      small
                      rounded
                      large
                      dark
                      block
                      color="program"
                      @click="validarcelular"
                    >
                      Enviar
                      <pre>&nbsp;</pre>
                      <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-dialog v-model="dialognoexiste" max-width="30%">
                  <v-card>
                    <br />
                    <v-card-text class="text-center">
                      <h2>El número de celular ingresado no esta registrado</h2>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogventa" max-width="70%">
                  <v-card>
                    <v-card-title class="headline">
                      Ingresar importe:
                    </v-card-title>
                    <v-card-text class="text-center">
                      <div class="text-h4">${{ saldo }}<br /></div>
                      <br />
                      <v-form ref="form2" onSubmit="return false;">
                        <v-text-field
                          prefix="$"
                          v-model="importe"
                          label="Importe Total"
                          outlined
                          :rules="specialrule"
                          dense
                          @keydown.enter="blurAll"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-col cols="6">
                        <v-btn
                          dense
                          title="Abonar"
                          small
                          rounded
                          large
                          dark
                          block
                          color="program"
                          @click="abonar"
                        >
                          <v-icon>mdi-dots-circle</v-icon>
                          Abonar
                        </v-btn>
                      </v-col>
                      <v-dialog v-model="dialoggana" persistent max-width="30%">
                        <v-card>
                          <br />
                          <v-card-text class="text-center">
                            <h2>El cliente gano saldo para el monedero</h2>
                          </v-card-text>
                          <v-card-actions>
                            <v-col cols="4"></v-col>
                            <v-col cols="4">
                              <v-btn
                                dense
                                title="Ok"
                                small
                                rounded
                                large
                                dark
                                block
                                color="program"
                                @click="reloadPage"
                              >
                                <v-icon>mdi-check</v-icon>
                                Ok
                              </v-btn>
                            </v-col>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-col cols="6">
                        <v-btn
                          dense
                          title="Pagar"
                          small
                          rounded
                          large
                          dark
                          block
                          color="program"
                          @click="pagar"
                        >
                          <v-icon>mdi-credit-card-outline</v-icon>
                          Pagar
                        </v-btn>
                      </v-col>
                      <v-dialog
                        v-model="dialogcodigo"
                        persistent
                        max-width="70%"
                      >
                        <v-card>
                          <v-card-title class="headline">
                            Ingresar el código:
                          </v-card-title>
                          <v-card-text class="text-center">
                            <br />
                            <v-form ref="form3" onSubmit="return false;">
                              <v-text-field
                                v-model="codigo"
                                label="Ingresar código"
                                outlined
                                :rules="[rules.required, rules.codigo]"
                                dense
                                @keydown.enter="blurAll"
                              ></v-text-field>
                            </v-form>
                          </v-card-text>
                          <v-card-actions>
                            <v-col cols="6">
                              <v-btn
                                dense
                                title="Verificar"
                                small
                                rounded
                                large
                                dark
                                block
                                color="program"
                                @click="verificar"
                              >
                                <v-icon>mdi-magnify</v-icon>
                                Verificar
                              </v-btn>
                            </v-col>
                            <v-col cols="6">
                              <v-btn
                                dense
                                title="Cancelar"
                                small
                                rounded
                                large
                                dark
                                block
                                color="program"
                                @click="dialogcodigo = false"
                              >
                                <v-icon>mdi-close</v-icon>
                                Cancelar
                              </v-btn>
                            </v-col>
                          </v-card-actions>
                          <v-dialog
                            v-model="dialogrestante"
                            persistent
                            max-width="70%"
                          >
                            <v-card>
                              <v-card-title class="headline">
                                Dinero faltante para completar la venta:
                              </v-card-title>
                              <v-card-text class="text-center">
                                <div class="text-h4">${{ restante }}<br /></div>
                                <br />
                              </v-card-text>
                              <v-card-actions>
                                <v-col cols="4"></v-col>
                                <v-col cols="4">
                                  <v-btn
                                    dense
                                    title="Ok"
                                    small
                                    rounded
                                    large
                                    dark
                                    block
                                    color="program"
                                    @click="reloadPage"
                                  >
                                    <v-icon>mdi-check</v-icon>
                                    Ok
                                  </v-btn>
                                </v-col>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog
                            v-model="dialogcodigoincorrecto"
                            max-width="30%"
                          >
                            <v-card>
                              <br />
                              <v-card-text class="text-center">
                                <h2>El código ingresado no es correcto</h2>
                              </v-card-text>
                            </v-card>
                          </v-dialog>
                        </v-card>
                      </v-dialog>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-dialog v-model="dialogcargando" hide-overlay persistent width="300">
          <v-card color="program" dark>
            <v-card-text>
              Cargando
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog persistent v-model="dialog500" max-width="350">
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text class="text-h6">
              <h5>Problemas de conexión</h5>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="program" text @click="dialog500 = false">
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog persistent v-model="dialog404" max-width="350">
          <v-card>
            <v-card-title class="headline"> Error </v-card-title>
            <v-card-text class="text-h6">
              <h5>{{ Message }}</h5>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="program" text @click="dialog404 = false">
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </v-card>
</template>

<style scoped>
</style>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  data: () => ({
    Message: "Internal Error",
    celular: "",
    importe: "",
    saldo: "",
    codigo: "",
    id_transferencia: "",
    id_negocio: "",
    restante: "",

    dialogventa: false,
    dialognoexiste: false,
    dialoggana: false,
    dialogcodigo: false,
    dialogrestante: false,
    dialogcodigoincorrecto: false,

    dialogcargando: false,
    dialog404: false,
    dialog500: false,

    rules: {
      required: (value) => !!value || "Requerido",
      cel: (value) => {
        const pattern = /^([0-9]{10}[0-9]*)$/;
        return pattern.test(value) || "Número inválido";
      },
      codigo: (value) => {
        const pattern = /^[0-9]{5}$/;
        return pattern.test(value) || "Requiere 5 digitos";
      },
      puntos: (value) => {
        const pattern = /^[0-9\u0020]{1,10}$/;
        return pattern.test(value) || "Caracteres inválidos, ejemplo: 10";
      },
    },
    f401: "Código Creado",
  }),
  computed: {
    specialrule() {
      const rules = [];
      const ruler = (v) => !!v || "Requerido";
      rules.push(ruler);
      const pattern =
        /^([1-9][0-9]*\.[0-9][0-9]|[0]\.[0-9][1-9]|[0]\.[1-9][0-9])$/;
      const rule = (value) => pattern.test(value) || "Invalido, ejemplo: 0.01";
      rules.push(rule);
      return rules;
    },
  },
  watch: {},
  methods: {
    validarcelular() {
      var vue = this;
      var a = this.$refs.form1.validate();
      if (a) {
        var Params = {
          celular: vue.celular,
        };
        axios
          .post("api/Monedero/GetSaldoClienteCelular", Params)
          .then(function (response) {
            if (response.data.edopet == 0) {
              vue.dialogventa = true;
            } else if (
              response.data.edopet == -2 ||
              response.data.edopet == -1
            ) {
              vue.dialognoexiste = true;
            }
            vue.saldo = response.data.monto;
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data.Message;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    abonar() {
      var vue = this;
      var a = this.$refs.form2.validate();
      if (a) {
        var Params = {
          celular: vue.celular,
          monto: vue.importe,
        };
        axios
          .post("api/Monedero/SaldoXCompra", Params)
          .then(function (response) {
            if (response.data.edopet == 0) {
              vue.dialogventa = false;
              vue.dialoggana = true;
            } else if (
              response.data.edopet == -2 ||
              response.data.edopet == -1
            ) {
              vue.dialog404 = true;
            }
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data.Message;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    pagar() {
      var vue = this;
      var a = this.$refs.form2.validate();
      if (a) {
        var Params = {
          celular: vue.celular,
          monto: vue.importe,
          id_negocio: vue.$store.state.id_negocio,
        };
        axios
          .post("api/Monedero/CobroMonedero", Params)
          .then(function (response) {
            if (response.data.edopet == 0) {
              vue.dialogcodigo = true;
              // vue.insertarhistorial();
            }
            vue.id_transferencia = response.data.id_transferencia;
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data.Message;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    insertarhistorial() {
      var vue = this;
      // var a = this.$refs.form2.validate();
      // if (a) {
        var Params = {
          user: vue.$store.state.user,
          negocio: vue.$store.state.negocio,
          monto: vue.importe,
          celular: vue.celular,
        };
        axios
          .post("api/Monedero/InsertarHistorial", Params)
          .then(function (response) {})
          .catch(function (error) {
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data.Message;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      // }
    },

    verificar() {
      var vue = this;
      var a = this.$refs.form3.validate();
      if (a + this.id_transferencia) {
        var Params = {
          codigo: vue.codigo,
          id_transferencia: vue.id_transferencia,
        };
        axios
          .post("api/Monedero/AplicaCobroMonedero", Params)
          .then(function (response) {
            vue.restante = response.data.restante;
            if (response.data.edopet == 0) {
              vue.dialogrestante = true;
              vue.actualizarhistorial();
            } else if (response.data.edopet == -1) {
              vue.dialogcodigoincorrecto = true;
            }
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data.Message;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    actualizarhistorial() {
      var vue = this;
      // var a = this.$refs.form3.validate();
      // if (a) {
        var Params = {
          codigo: vue.codigo,
          user: vue.$store.state.user,
        };
        axios
          .post("api/Monedero/ActualizarHistorial", Params)
          .then(function (response) {})
          .catch(function (error) {
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data.Message;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      // }
    },

    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },

    clear() {
      this.$refs.form1.reset();
      this.$refs.form2.reset();
      this.$refs.form3.reset();
    },

    blurall() {
      var tmp = document.createElement("input");
      document.body.appendChild(tmp);
      tmp.focus();
      document.body.removeChild(tmp);
    },
  },

  mounted() {
    this.$store.dispatch("autologin", {
      franquiciante: false,
      franquiciatario: true,
      empleado: true,
      cliente: false,
    });
  },
};
</script>