<template>
  <v-row>
    <v-col>
      <v-row>
        <v-btn large dark color="program" text @click="funcreate">
          <v-icon dark small color="program">mdi-account-plus</v-icon>
          <pre>&nbsp;</pre>
          Crear Cuenta
        </v-btn>
        <v-spacer />
        <v-btn large dark color="program" text @click="contago">
          <v-icon dark small color="program">mdi-phone</v-icon>
          <pre>&nbsp;</pre>
          Contáctanos
        </v-btn>
      </v-row>
      <v-card
        class="mx-auto my-12"
        max-width="500"
        outlined
        shaped
        style="border-width: 30px; background-color: #005445"
      >
        <v-card>
          <br />
          <v-row dense>
            <v-col cols="1"></v-col>
            <v-col cols="10">
              <v-row>
                <v-spacer></v-spacer>
                <v-img
                  src="@/assets/logo_contago.png"
                  transition="scale-transition"
                  max-height="200"
                  max-width="300"
                />
                <v-spacer></v-spacer>
              </v-row>
              <v-col cols="4"></v-col>
              <v-col cols="12">
                <v-form ref="form1" onSubmit="return false;">
                  <v-text-field
                    v-model="datos.user"
                    label="Usuario"
                    outlined
                    small
                    :rules="[rules.required]"
                    dense
                    prepend-inner-icon="mdi-account"
                    @keydown.enter="login"
                  ></v-text-field>
                </v-form>
                <v-form ref="form2" onSubmit="return false;">
                  <v-text-field
                    v-model="datos.pass"
                    type="password"
                    label="Contraseña"
                    outlined
                    small
                    :rules="[rules.required]"
                    dense
                    prepend-inner-icon="mdi-key"
                    @keydown.enter="login"
                  ></v-text-field>
                </v-form>
              </v-col>
              <br />
              <v-row>
                <v-spacer></v-spacer>
                <v-btn large dark color="program" @click="login">
                  Iniciar Sesión
                  <v-icon>mdi-login</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
              </v-row>
              <br />
              <br />
              <v-row>
                <v-spacer></v-spacer>
                <!-- <v-btn
                  large
                  rounded
                  outlined
                  color="program"
                  @click="logingoogle"
                >
                  Ingresar con
                  <v-avatar size="40">
                    <v-img src="../assets/google-logo.png"></v-img>
                  </v-avatar>
                </v-btn> -->
                <v-spacer></v-spacer>
              </v-row>
              <br />
            </v-col>
          </v-row>
          <br />
        </v-card>
      </v-card>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Iniciando Sesión
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
  
  
  <script>
import axios from "axios";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithGoogle,
  signInWithCredential,
  getRedirectResult,
} from "firebase/auth";
// import { auth } from "./src/firebase";
import { initializeApp } from "firebase/app";
import { ref } from "vue";

// const logingoogle = async() => {
//   try {
//     const provider = new GoogleAuthProvider()
//     const {user} = await signInWithPopup(auth, provider)
//     console.log(user)
//   } catch (error) {
//     console.log(error)
//   }
// };

export default {
  data: () => ({
    Message: "Internal Error",
    dialogIni: false,
    dialog404: false,
    dialog500: false,
    datos: {
      user: "",
      pass: "",
    },
    rules: {
      required: (value) => !!value || "Requerido",
    },
  }),
  methods: {
    // logingoogle() {
    //   const provider = new firebase.auth.GoogleAuthProvider();

    //   firebase.auth().signInWithPopup(provider).then((result) => {
    //     this.$router.push({ name: "Saldo" });
    //     // this.$router.replace('Saldo');
    //   }).catch((err) => {
    //     alert('Oops. ' + err.message)
    //   });
    // },

    async logingoogle() {
      try {
        const provider = new GoogleAuthProvider();
        const { user } = await signInWithPopup(auth, provider);
        console.log(user);
      } catch (error) {
        console.log(error);
      }
    },

    login() {
      var vue = this;
      var a = this.$refs.form1.validate();
      var b = this.$refs.form2.validate();
      if (a && b) {
        vue.dialogIni = true;
        var Params = {
          user: vue.datos.user,
          pass: vue.datos.pass,
        };
        axios
          .post("api/Monedero/LoginUsuarios", Params)
          .then(function (response) {
            vue.dialogIni = false;
            vue.$store.dispatch("guardaruser", response.data.user);
            vue.$store.dispatch("guardarpass", response.data.pass);
            vue.$store.dispatch("guardartipo", response.data.tipo);
            vue.$store.dispatch("guardarnegocio", response.data.negocio);
            vue.$store.dispatch("guardarid_negocio", response.data.id_negocio);
            document.getElementById("todo").style.display = "block";
            console.log(response.data);
            if (
              response.data.tipo == "Franquiciatario" ||
              response.data.tipo == "Empleado"
            ) {
              location.href = "Venta";
            } else if (response.data.tipo == "Franquiciante") {
              location.href = "Datos";
            } else {
              location.href = "Saldo";
            }
          })
          .catch(function (error) {
            vue.dialogIni = false;
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
            vue.$store.dispatch("guardaruser", "");
            vue.$store.dispatch("guardarpass", "");
            vue.$store.dispatch("guardartipo", "");
            vue.$refs.form1.reset();
            vue.$refs.form2.reset();
          });
      }
    },
    funcreate() {
      // this.$router.push({name: "Crear_Cuenta" });
      location.href = "Crear_Cuenta";
      document.getElementById("todo").style.display = "none";
    },
    contago() {
      window.open("https://contago.com.mx");
    },
  },
  mounted() {
    this.$store.dispatch("homelogin");
  },
};
</script>