import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import router from '../router/index'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {
        nombre: null,
        tipo: null,
        user: null,
        pass: null,
        negocio: null,
        id_negocio: null,
    },
    mutations: {
        setTipo(state, tipo) {
            state.tipo = tipo
        },
        setUser(state, user) {
            state.user = user
        },
        setPass(state, pass) {
            state.pass = pass
        },
        setNegocio(state, negocio) {
            state.negocio = negocio
        },
        setNombre(state, nombre) {
            state.nombre = nombre
        },
        setIdNegocio(state, id_negocio) {
            state.id_negocio = id_negocio
        },
    },
    actions: {
        guardartipo({ commit }, tipo) {
            commit("setTipo", tipo)
            localStorage.setItem("tipo", tipo)
        },
        guardaruser({ commit }, user) {
            commit("setUser", user)
            localStorage.setItem("user", user)
        },
        guardarpass({ commit }, pass) {
            commit("setPass", pass)
            localStorage.setItem("pass", pass)
        },
        guardanegocio({ commit }, negocio) {
            commit("setNegocio", negocio)
            localStorage.setItem("negocio", negocio)
        },
        guardarnombre({ commit }, nombre) {
            commit("setNombre", nombre)
            localStorage.setItem("nombre", nombre)
        },
        guardarid_negocio({ commit }, id_negocio) {
            commit("setIdNegocio", id_negocio)
            localStorage.setItem("id_negocio", id_negocio)
        },
        autologin({ commit }, permisos) {
            let u = localStorage.getItem("user")
            let p = localStorage.getItem("pass")
            var Params = {
                user: u,
                pass: p,
            };
            axios
                .post("api/Monedero/LoginUsuarios", Params)
                .then(function (response) {
                    commit("setTipo", response.data.tipo)
                    localStorage.setItem("tipo", response.data.tipo)
                    commit("setNombre", response.data.nombre)
                    localStorage.setItem("nombre", response.data.nombre)
                    commit("setUser", response.data.user)
                    localStorage.setItem("user", response.data.user)
                    commit("setPass", response.data.pass)
                    localStorage.setItem("pass", response.data.pass)
                    commit("setNegocio", response.data.negocio)
                    localStorage.setItem("negocio", response.data.negocio)
                    commit("setIdNegocio", response.data.id_negocio)
                    localStorage.setItem("id_negocio", response.data.id_negocio)
                    document.getElementById("todo").style.display = "block";
                    if (response.data.tipo == "Franquiciante") {
                        if (!permisos.franquiciante) {
                            router.push({ name: "Datos" });
                        }
                    }
                    else if (response.data.tipo == "Franquiciatario") {
                        if (!permisos.franquiciatario) {
                            router.push({ name: "Venta" });
                        }
                    }
                    else if (response.data.tipo == "Empleado") {
                        if (!permisos.empleado) {
                            router.push({ name: "Venta" });
                        }
                    }
                    else {
                        if (!permisos.cliente) {
                            router.push({ name: "Saldo" });
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    commit("setTipo", null)
                    commit("setNombre", null)
                    commit("setUser", null)
                    commit("setPass", null)
                    commit("setNegocio", null)
                    commit("setIdNegocio", null)
                    localStorage.removeItem("tipo")
                    localStorage.removeItem("nombre")
                    localStorage.removeItem("user")
                    localStorage.removeItem("pass")
                    localStorage.removeItem("negocio")
                    localStorage.removeItem("id_negocio")
                    document.getElementById("todo").style.display = "none";
                    router.push({ name: "Login" });
                });
        },

        homelogin({ commit }) {
            let u = localStorage.getItem("user")
            let p = localStorage.getItem("pass")
            var Params = {
                user: u,
                pass: p,
            };
            axios
                .post("api/Monedero/LoginUsuarios", Params)
                .then(function (response) {
                    commit("setTipo", response.data.tipo)
                    localStorage.setItem("tipo", response.data.tipo)
                    commit("setNombre", response.data.nombre)
                    localStorage.setItem("nombre", response.data.nombre)
                    commit("setUser", response.data.user)
                    localStorage.setItem("user", response.data.user)
                    commit("setPass", response.data.pass)
                    localStorage.setItem("pass", response.data.pass)
                    commit("setNegocio", response.data.negocio)
                    localStorage.setItem("negocio", response.data.negocio)
                    commit("setIdNegocio", response.data.id_negocio)
                    localStorage.setItem("id_negocio", response.data.id_negocio)
                    document.getElementById("todo").style.display = "block";
                    if (response.data.tipo == "Franquiciante") {
                        router.push({ name: "Datos" });
                    }
                    else if (response.data.tipo == "Franquiciatario") {
                        router.push({ name: "Venta" });
                    }
                    else if (response.data.tipo == "Empleado") {
                        router.push({ name: "Venta" });
                    }
                    else {
                        router.push({ name: "Saldo" });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    commit("setTipo", null)
                    commit("setNombre", null)
                    commit("setUser", null)
                    commit("setPass", null)
                    commit("setNegocio", null)
                    commit("setIdNegocio", null)
                    localStorage.removeItem("tipo")
                    localStorage.removeItem("nombre")
                    localStorage.removeItem("user")
                    localStorage.removeItem("pass")
                    localStorage.removeItem("negocio")
                    localStorage.removeItem("id_negocio")
                    document.getElementById("todo").style.display = "none";
                });
        },
        appsalir({ commit }) {
            commit("setTipo", null)
            commit("setNombre", null)
            commit("setUser", null)
            commit("setPass", null)
            commit("setNegocio", null)
            commit("setIdNegocio", null)
            localStorage.removeItem("tipo")
            localStorage.removeItem("nombre")
            localStorage.removeItem("user")
            localStorage.removeItem("pass")
            localStorage.removeItem("negocio")
            localStorage.removeItem("id_negocio")
            document.getElementById("todo").style.display = "none";
            router.push({ name: "Login" });
        },
        nada({ commit }) {
            commit("setTipo", null)
            commit("setNombre", null)
            commit("setUser", null)
            commit("setPass", null)
            commit("setNegocio", null)
            commit("setIdNegocio", null)
            localStorage.removeItem("tipo")
            localStorage.removeItem("nombre")
            localStorage.removeItem("user")
            localStorage.removeItem("pass")
            localStorage.removeItem("negocio")
            localStorage.removeItem("id_negocio")
            document.getElementById("todo").style.display = "none";
        },
    },
})