<template>
  <v-app>
    <v-card class="mx-auto overflow-hidden" tile height="100%" width="100%">
      <v-app-bar dense color="program" src="@/assets/fondo.jpg" dark>
        <v-col cols="12" xs="12" id="todo" class="to">
          <v-img></v-img>
          <v-row>
            <v-badge
              :content="messages"
              :value="messages"
              color="warning"
              overlap
              bottom
            >
              <v-app-bar-nav-icon
                dense
                title="Menú"
                @click.stop="drawer = !drawer"
              >
                <v-img
                  src="@/assets/logo.png"
                  transition="scale-transition"
                  max-height="50"
                  max-width="50"
                />
              </v-app-bar-nav-icon>
            </v-badge>
            <v-divider class="mx-4" vertical></v-divider>
            <v-spacer style="display: flex; min-width: 1%" />
            <pre class="name"><v-toolbar-title>
              {{ $store.state.user }}</v-toolbar-title>
            </pre>
            <v-divider class="mx-4" vertical></v-divider>
            <v-btn title="Cerrar Sesión" @click="dialog = true" icon>
              <v-icon large>mdi-logout-variant</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-app-bar>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-navigation-drawer v-model="drawer" absolute temporary app clipped>
        <v-list>
          <template>
            <!-- Empleados -->
            <template v-if="$store.state.tipo == 'Empleado'">
              <v-list-item :to="{ path: '/Venta' }">
                <v-list-item-action>
                  <v-icon>mdi-cash-register</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Venta</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ path: '/Promociones' }">
                <v-list-item-action>
                  <v-icon>mdi-sale</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Promociones</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ path: '/Historial' }">
                <v-list-item-action>
                  <v-icon>mdi-history</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Historial</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <!-- Franquiciatario -->
            <template v-else-if="$store.state.tipo == 'Franquiciatario'">
              <v-list-item :to="{ path: '/Venta' }">
                <v-list-item-action>
                  <v-icon>mdi-cash-register</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Venta</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ path: '/Promociones' }">
                <v-list-item-action>
                  <v-icon>mdi-sale</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Promociones</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ path: '/Crear_Usuarios' }">
                <v-list-item-action>
                  <v-icon>mdi-account-group</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Usuarios</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ path: '/Historial' }">
                <v-list-item-action>
                  <v-icon>mdi-history</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Historial</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <!-- Franquiciante -->
            <template v-else-if="$store.state.tipo == 'Franquiciante'">
              <v-list-item :to="{ path: '/Datos' }">
                <v-list-item-action>
                  <v-icon>mdi-file-chart-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Estado Financiero</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ path: '/Promociones' }">
                <v-list-item-action>
                  <v-icon>mdi-sale</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Promociones</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <!-- Clientes -->
            <template v-else>
              <v-list-item :to="{ path: '/Saldo' }">
                <v-list-item-action>
                  <v-icon>mdi-currency-usd</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Mi Saldo</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ path: '/Promociones' }">
                <v-list-item-action>
                  <v-icon>mdi-sale</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Promociones</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ path: '/Configuracion' }">
                <v-list-item-action>
                  <v-icon>mdi-cog</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Configuración</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-container fluid>
          <v-fade-transition mode="out-in">
            <router-view></router-view>
          </v-fade-transition>
        </v-container>
      </v-main>
    </v-card>
    <v-dialog persistent v-model="dialog" max-width="350">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text class="text-h6">
          <h5>¿Desea cerrar la sesión?</h5>
        </v-card-text>
        <v-card-actions>
          <v-btn color="program" text @click="dialog = false">
            <h4>Cancelar</h4>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="program" text @click="appsalir">
            <h4>Aceptar</h4>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<style>
.to {
  display: none;
}
.name {
  min-width: 40%;
  max-width: 41%;
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  align-self: center;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>

<script>
export default {
  name: "App",

  data: () => ({
    dialog: false,
    dialog500: false,
    drawer: false,
    group: null,
    messages: 0,
  }),
  methods: {
    appsalir() {
      this.dialog = false;
      this.$store.dispatch("appsalir");
    },
  },

  /*
  Error AXIOS
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  */
};
</script>
