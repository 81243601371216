import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'

//Vue.config.productionTip = false;
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.baseURL = "https://monedero.contago.com.mx/";
// axios.defaults.baseURL = "http://localhost:53447/";


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
